@tailwind base;
@tailwind components;
@tailwind utilities;

[hidden] {
  display: none;
}

body:has(dialog[open]) div[data-id="zsalesiq"], html:has(dialog[open]) div[data-id="zsalesiq"] {
  display: none !important
}
.ReactCollapse--collapse {
  transition: height 300ms;
}

body:has(dialog[open]),html:has(dialog[open]) {
  overflow: hidden;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none
}
input[type="search"]::-webkit-search-cancel-button {
  display: none;
}

.Toastify__toast-icon{
  width: auto;
}

:root {
  --toastify-color-success: #49B715;
  --toastify-color-error: #FF4D1A;
  --toastify-font-family: var(--font-primary);
  --toastify-toast-offset: 46px;
}

/* media 768px */
@media (min-width: 768px) {
  :root {
    --toastify-toast-offset: 100px
  }
}

@layer base {
  legend {
    display: block;
  }
  b {
    font-weight: bold
  }
  ul,ol {
    @apply ml-6 mt-2;
    list-style: initial;
  }
  img {
    all: revert-layer;
    display: block;
    box-sizing: border-box;
  }
}

@layer components {
  .list_icons li{
    @apply mb-4;
    list-style-image: url(../icons/e.svg);
  }
  .text-title {
    @apply text-4xl font-extrabold text-secondary mb-5;
  }
  .page-title {
    @apply text-center text-xl font-extrabold mt-6 mb-4 sm:text-title
  }
}

@layer utilities {
   
}
